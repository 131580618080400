<template>
	<div>
		<div class="row" v-if="data.remote && data.ucp">
			<div class="col-md-4">Downstream C/N[dB] and NCR Lost</div>
			<div class="col-md-2">snr db = {{ data.ucp[data.ucp.length - 1].snr_cal }}</div>
			<div class="col-md-2">
				NCR Lost = {{ data.remote[data.remote.length - 1].lostlock_count }}
			</div>
		</div>
<div class="row">
			<div class="col-md-12">
				<dygraphs
					:data="graphs.rem.data"
					:options="satcom.downcn.opts"
					:key="'downcn' + graphs.rem.data.length"
				/>
				<dygraphs
					:data="satcom.ncrlost.data"
					:options="satcom.ncrlost.opts"
					:key="'ncr' + satcom.ncrlost.data.length"
				/>
			</div>
		</div>
		<div class="row" v-if="data.remote && data.remote.length > 0">
			<div class="col-md-4">Upstream C/N[dB] and Tx Power[dBm]</div>
			<div class="col-md-2">snr db = {{ data.remote[data.remote.length - 1].snr_cal }}</div>
			<div class="col-md-2">tx pwr = {{ data.remote[data.remote.length - 1].power_in_dbm }}</div>
		</div>
		<div class="row" v-if="graphs.snr.data && graphs.snr.data.length > 0">
			<div class="col-md-12">
				<dygraphs :data="graphs.snr.data" :options="satcom.upcn.opts" :key="'upcn' + graphs.snr.data.length" />
				<dygraphs
					:data="satcom.txpower.data"
					:options="satcom.txpower.opts"
					:key="'txp' + satcom.txpower.data.length"
				/>
			</div>
		</div>
		<div class="row" v-if="data.ucp && data.ucp.length > 0">
			<div class="col-md-4">Symbol and Frequency Offsets</div>
			<div class="col-md-2">sym off = {{ data.ucp[data.ucp.length - 1].sym_offset }}</div>
			<div class="col-md-2">freq off = {{ data.ucp[data.ucp.length - 1].freq_offset }}</div>
		</div>
		<div
			class="row"
			v-if="
				satcom.symoff.data &&
				satcom.freqoff.data &&
				satcom.symoff.data.length &&
				satcom.freqoff.data.length
			"
		>
			<div class="col-md-12">
				<dygraphs
					:data="satcom.symoff.data"
					:options="satcom.symoff.opts"
					:key="'sym' + satcom.symoff.data.length"
				/>
				<dygraphs
					:data="satcom.freqoff.data"
					:options="satcom.freqoff.opts"
					:key="'freq' + satcom.freqoff.data.length"
				/>
			</div>
		</div>		
		
	</div>
</template>

<script>

export default {
	props: ['data', 'satcom', 'graphs'],
	data() {
		return {};
	},
	components: { dygraphs: () => import('@/view/content/lib/dygraphs.vue') },
};
</script>

<style>
</style>